import { useToothNotation } from '@Features/Explorer/ExplorerContent/Hooks/useToothNotation';
import { useMemo } from 'react';
import {
  makeCrownLabelList,
  makeModelerLabelList
} from '@Features/Explorer/ExplorerContent/Utils';
import { useTranslation } from 'react-i18next';
import { TDesignCase } from '@Features/Explorer/Shared/Types/designCase';
import { map } from '@Shared/Utils/helpers';

// common i18n 변경 예정
const CROWN_TOOTH_LABEL = {
  Crown: 'explorer:ui_component_label.crown_tooth_number' as const,
  'In-onlay': 'explorer:ui_component_label.inonlay_toothnumber' as const,
  Bridge: 'explorer:ui_component_label.bridge_tooth_number' as const,
  Pontic: 'explorer:ui_component_label.pontic' as const
};
const MODELER_LABEL = {
  'Lower model': 'explorer:ui_component_label.lower_model' as const,
  'Upper model': 'explorer:ui_component_label.upper_model' as const
};

export const useProductLabel = (item?: TDesignCase) => {
  const { notation } = useToothNotation();
  const { t, i18n } = useTranslation();
  const labelList = useMemo(() => {
    if (!item) return [];
    return item?.solution === 'MODELER'
      ? makeModelerLabelList(item?.products ?? []).map((label) =>
          t(MODELER_LABEL[label as keyof typeof MODELER_LABEL])
        )
      : [
          ...map(
            ({ label, tooth }) =>
              `${t(CROWN_TOOTH_LABEL[label as keyof typeof CROWN_TOOTH_LABEL], {
                variable: ''
              })} ${tooth}`,
            makeCrownLabelList({ ...item, notation })
          )
        ];
  }, [item, notation, i18n.language]);
  return { labelList };
};
