import { useInitialDataState } from '@Shared/Atoms/InitialData/useInitialDataState';
import { useCaseListPageContext } from '../../Context';
import { useDialogue } from '@Shared/Hooks/useDialogue';
import { useErrorState } from '@Shared/Atoms/Error/useErrorState';
import { getDesignCaseInfo } from '../Apis/openCaseInfo';
import {
  onCloseApp,
  onImportSuccess,
  onRedirectApp
} from '@Shared/Utils/postMessage';
import { AxiosError } from 'axios';
import { useModuleContext } from '@imago/imago-modules';
import { MODULE_EXPLORER_TYPE } from '@Shared/Constants/initialData';
import { CROWN_URL, MODELER_URL } from '@Src/config';

type TAxiosError = AxiosError<{
  errorCode: string;
  message: string;
  success: boolean;
}>;

export const useExplorerActions = () => {
  const { initialData } = useInitialDataState();
  const { selectedItem } = useCaseListPageContext();
  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();
  const { onUnknownError } = useErrorState();

  const {
    export: { onOpen }
  } = useModuleContext();

  const checkDesignCaseIdValidation = async () => {
    const { data: _data } = await getDesignCaseInfo(selectedItem?.id ?? '');
    const { data } = _data ?? {};

    return {
      success: true,
      id: data?.id,
      solution: data?.solution
    };
  };

  const loadDesignCase = async () => {
    const { success } = await checkDesignCaseIdValidation();
    if (success) {
      onOpen({
        initialData: {
          exactHost: 'explorerModule',
          designCaseIds: [selectedItem?.id ?? '']
        },
        onSuccess: (
          res: {
            designCaseId: string;
            designCaseName: string;
            files: File[];
          }[]
        ) => {
          const files = res[0]?.files;
          onImportSuccess(files);
          onCloseApp();
        }
      });
    } else {
      handleOpenDialogue();
    }
  };
  const getSolutionRoute = (solution: string) => {
    switch (solution) {
      case 'CROWN':
        return `${CROWN_URL}/crown?caseUniqueID=`;
      case 'MODELER':
        return `${MODELER_URL}/modeler-case?caseId=`;
      default:
        return '';
    }
  };
  const openDesignCase = async () => {
    const { success, solution } = await checkDesignCaseIdValidation();
    if (success) {
      onRedirectApp(`${getSolutionRoute(solution)}${selectedItem?.id ?? ''}`);
    } else {
      handleOpenDialogue();
    }
  };

  const onActionButtonClick = async () => {
    try {
      if (initialData.importType === MODULE_EXPLORER_TYPE.OPENER) {
        await openDesignCase();
      } else {
        await loadDesignCase();
      }
    } catch (err) {
      const errorCode = (err as TAxiosError).response?.data?.errorCode;
      if (errorCode === 'DESIGN_CASE:DESIGN_CASE_NOT_FOUND') {
        handleOpenDialogue();
        return;
      }
      onUnknownError();
    }
  };

  return {
    open,
    isImportEnabled: !!selectedItem,
    onActionButtonClick,
    handleCloseDialogue
  };
};
