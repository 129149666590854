import { Stack } from '@mui/material';
import { Checkbox, MenuItem, Select } from '@imago-cloud/design-system';
import { useCaseListSolutionFilter } from '@Features/Explorer/ExplorerHeader/Hooks/useCaseListSolutionFilter';
import { SolutionType } from '@Features/Explorer/Shared/Types/designCase';
import { useTranslation } from 'react-i18next';

const SOLUTION_FILTER = [
  {
    name: 'All',
    value: 'ALL',
    i18nKey: 'explorer:ui_component.solution_menu_all' as const
  },
  {
    name: 'Crown',
    value: 'CROWN'
  },
  {
    name: 'Modeler',
    value: 'MODELER'
  }
];

export const CaseSolutionFilter = () => {
  const {
    isDisabled,
    solution,
    platform,
    changeSolutionQueryOption,
    changePlatformQueryOption
  } = useCaseListSolutionFilter();
  const { t } = useTranslation();

  return (
    <Stack flexDirection={'row'} gap={'20px'}>
      <Select
        disabled={isDisabled}
        onChange={(e) => {
          changeSolutionQueryOption(e.target.value as SolutionType);
        }}
        value={
          SOLUTION_FILTER.find((item) => item.value === solution)?.value ??
          'ALL'
        }
        size={'36'}
        sx={{
          width: '320px'
        }}
      >
        {SOLUTION_FILTER.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.i18nKey ? t(item.i18nKey) : item.name}
            </MenuItem>
          );
        })}
      </Select>
      {solution !== 'MODELER' && (
        <Checkbox
          label={t('explorer:ui_component.check_box_view_batch_case')}
          size={'16'}
          checked={platform !== 'GENERAL'}
          onClick={() => changePlatformQueryOption(platform === 'GENERAL')}
        />
      )}
    </Stack>
  );
};
