import { shining3DAxiosInstance } from '../../Shared/Apis/axiosInstance';
import {
  SHINING_3D_AUTHORIZATION_LIST_URL,
  SHINING_3D_ORDER_LIST_URL
} from '../Constants/apiUrl';
import { TDate } from '@Shared/Types/dateType';

export const getThirdPartyFactories = async (token: string) => {
  const res = await shining3DAxiosInstance.get(
    SHINING_3D_AUTHORIZATION_LIST_URL,
    {
      params: { orgCode: process.env.REACT_APP_DENTBIRD_ORG_CODE },
      headers: {
        'X-Auth-AppKey': process.env.REACT_APP_SHINING3D_APP_KEY ?? '',
        'X-Auth-Token': token
      }
    }
  );
  return res.data;
};

export const getShining3dOrderList = async ({
  date,
  orgCode,
  orgType,
  token,
  pageParam
}: {
  date: TDate;
  orgCode: string | null;
  orgType: string | null;
  token: string;
  pageParam: number;
}) => {
  const formatType = 'YYYY-MM-DD';

  const startDate = date?.startDate?.format(formatType);
  const endDate = date?.endDate?.add(1, 'day')?.format(formatType);

  return await shining3DAxiosInstance
    .get(SHINING_3D_ORDER_LIST_URL, {
      params: {
        pageSize: 15,
        page: pageParam,
        orgType,
        orgCode,
        startOn: startDate,
        endOn: endDate
      },
      headers: {
        'X-Auth-AppKey': process.env.REACT_APP_SHINING3D_APP_KEY ?? '',
        'X-Auth-Token': token
      }
    })
    .then((res) => res.data);
};
