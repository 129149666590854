import {
  entries,
  filter,
  groupBy,
  join,
  map,
  reduce,
  shorten,
  sortBy
} from '@Shared/Utils/helpers';

import {
  TModelerUnit,
  TProduct,
  TProductGroup
} from '../../Shared/Types/product';
import {
  MODELER_UNIT_TYPE,
  PRODUCT_GROUP_TYPE,
  PRODUCT_TYPE
} from '../../Shared/Constants/productInfo';
import {
  FDINumber,
  toADANumber
} from '../../Shared/Utils/toothNumberingSystem';
import { TDesignCase } from '@Features/Explorer/Shared/Types/designCase';
import { ADANumberingSystem } from '@imago-deep-cad/tooth-numbering-system';

const sortByADA = (a: TProduct, b: TProduct) => {
  return compareByUnitValue('ADA')(a, b);
};

const compareByUnitValue =
  (notation: 'FDI' | 'ADA') => (a: TProduct, b: TProduct) =>
    notation === 'FDI'
      ? parseInt(a.value) - parseInt(b.value)
      : ADANumberingSystem.toNumber(toADANumber(a.value as FDINumber)) -
        ADANumberingSystem.toNumber(toADANumber(b.value as FDINumber));

const makeImplantNumber = (product: TProduct) => {
  return product.productType === 'IMPLANT_CROWN'
    ? product.value + 'i'
    : product.value;
};

const findGroupType = (productGroup: TProductGroup[], groupId: string) => {
  return productGroup.find(({ id }) => id === groupId)?.groupType;
};

const makeToothLabelTitle = (type: string, productGroup: TProductGroup[]) => {
  const groupType = findGroupType(productGroup, type);

  if (groupType) return PRODUCT_GROUP_TYPE[groupType];

  return type;
};

const makeToothNumbers = (products: TProduct[]) => {
  return products[0].groupId
    ? shorten('-', Array.from(map(makeImplantNumber, products)))
    : join(',', map(makeImplantNumber, products));
};

const makeModelerLabel = (product: TProduct) => {
  return MODELER_UNIT_TYPE[product.value as TModelerUnit];
};

const makeCrownLabelList = ({
  products,
  productGroups,
  notation
}: TDesignCase & { notation: 'FDI' | 'ADA' }) => {
  const groupedProducts = groupBy(
    (product) => product.groupId ?? PRODUCT_TYPE[product.productType],
    filter((el) => el.productType in PRODUCT_TYPE, products) // nc file filtering
  );

  return reduce(
    (acc, { key, value }) => {
      const label = makeToothLabelTitle(key, productGroups);
      const tooth = makeToothNumbers([
        ...map(
          (product) => changeToothNotation(product, notation),
          sortBy(
            label === 'Bridge' ? sortByADA : compareByUnitValue(notation),
            value
          )
        )
      ]);

      return [...acc, { label, tooth }];
    },
    entries(groupedProducts),
    [] as { label: string; tooth: string }[]
  );
};

const makeModelerLabelList = (products: TProduct[]) => {
  return Array.from(map(makeModelerLabel, products));
};

const changeToothNotation = (product: TProduct, notation: string): TProduct =>
  notation === 'FDI'
    ? product
    : {
        ...product,
        value: toADANumber(product.value as FDINumber) as FDINumber
      };

export { makeModelerLabelList, makeCrownLabelList };
